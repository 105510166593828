

body {
	background-color: #f4f4f4;
}

/* .ui.vertical.menu .active.item {
	background-color: #b4d5ff;
}


.ui.vertical.pointing.menu .active.item:after {
	background-color: #b4d5ff;
}


.ui.vertical.menu .active.item:hover {
	background-color: #3399ff;
}

.ui.vertical.pointing.menu:hover .active.item:hover:after {
	background-color: #3399ff;
} */